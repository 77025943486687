import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
            <path d="M2445 6830 c-3 -5 -24 -10 -46 -10 -21 0 -39 -4 -39 -9 0 -5 84 -9
198 -9 108 0 226 0 262 -1 l65 -1 -3 -80 c-2 -52 -8 -81 -15 -82 -36 -4 -143
-5 -150 -1 -7 4 -98 6 -147 4 -5 -1 -10 -23 -10 -51 0 -27 5 -50 10 -50 6 0
10 5 10 10 0 6 9 10 20 10 13 0 20 7 20 19 0 11 5 23 10 26 6 4 10 -5 10 -19
0 -24 2 -25 20 -14 11 7 20 22 20 34 0 28 26 39 33 14 4 -15 11 -17 46 -10 22
4 41 4 41 0 0 -4 -18 -10 -40 -14 -27 -4 -40 -11 -40 -22 0 -12 4 -14 13 -6 6
5 39 13 71 17 33 4 54 10 47 14 -6 4 43 8 110 10 67 1 130 6 140 12 13 6 19 6
19 -1 0 -15 203 -13 219 3 11 10 14 10 18 0 3 -7 13 -13 23 -13 22 0 38 19 18
21 -7 1 0 4 16 8 28 6 29 7 12 24 -19 18 -66 24 -66 7 0 -5 12 -10 27 -10 32
0 27 -7 -10 -14 -28 -5 -34 5 -21 38 8 21 27 20 66 -4 35 -21 78 -27 78 -10 0
6 -4 10 -10 10 -5 0 -10 5 -10 10 0 16 30 12 36 -5 7 -18 -14 -47 -27 -39 -5
3 -9 0 -9 -5 0 -6 -9 -11 -20 -11 -11 0 -20 -4 -20 -10 0 -5 -8 -10 -17 -10
-10 0 -13 -3 -8 -9 16 -14 155 -25 155 -12 0 7 -22 11 -57 11 -46 1 -54 3 -40
11 11 7 43 9 75 6 32 -3 48 -3 37 0 -37 9 -37 20 1 26 44 7 64 -9 64 -52 0
-21 5 -31 15 -31 8 0 15 5 15 11 0 6 6 9 13 6 6 -2 11 -10 10 -16 -5 -18 11
-13 25 9 7 11 18 20 25 20 7 0 23 13 34 29 l21 28 -85 28 c-55 18 -105 27
-139 26 -29 0 -56 4 -59 9 -3 6 2 11 12 11 10 1 2 5 -17 9 -19 5 -45 11 -57
15 -14 5 -23 3 -23 -3 0 -6 8 -13 18 -15 15 -4 15 -5 0 -6 -9 0 -20 4 -23 9
-3 6 -17 10 -31 10 -13 0 -24 -4 -24 -10 0 -5 -7 -10 -15 -10 -13 0 -17 12
-14 37 2 18 -49 28 -176 33 -77 4 -122 8 -100 9 22 2 48 2 59 1 11 -1 16 3 11
10 -4 7 -30 9 -78 5 -45 -4 -113 -1 -181 9 -124 18 -431 22 -441 6z m145 -10
c11 -7 -3 -10 -47 -10 -36 0 -63 4 -63 10 0 13 90 13 110 0z m173 3 c-24 -2
-62 -2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43 -4z m537 -73 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m325 -66 c13 -5 14
-8 3 -15 -19 -13 -28 -11 -28 6 0 17 2 18 25 9z m101 -35 c15 -17 16 -22 4
-29 -17 -11 -25 -4 -35 28 -8 28 6 28 31 1z m-733 -16 c-7 -2 -19 -2 -25 0 -7
3 -2 5 12 5 14 0 19 -2 13 -5z m270 0 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4
25 0 35 -2 23 -4z"/>
<path d="M2968 6833 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2308 6797 c-10 -24 -60 -46 -66 -29 -5 17 -27 15 -42 -3 -7 -8 -22
-15 -34 -15 -11 -1 -32 -7 -46 -16 -24 -14 -22 -14 25 -8 28 4 54 13 58 20 8
14 37 20 37 8 0 -4 -8 -13 -17 -21 -16 -13 -12 -13 32 -8 61 8 67 13 63 56 -2
25 -5 29 -10 16z"/>
<path d="M2029 6681 c-13 -10 -27 -19 -32 -19 -4 -1 -15 -5 -24 -10 -12 -7 -5
-10 26 -10 35 -1 43 3 51 23 5 12 15 25 22 28 9 4 8 6 -3 6 -9 1 -27 -7 -40
-18z"/>
<path d="M2065 6661 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M1939 6614 c-12 -14 -11 -16 5 -13 11 2 21 10 24 17 5 17 -13 15 -29
-4z"/>
<path d="M1902 6580 c-11 -18 -10 -20 8 -17 11 2 23 6 26 10 4 4 13 2 19 -3
14 -11 45 -3 45 11 0 5 -12 9 -27 8 -16 -1 -35 1 -43 5 -9 4 -20 -1 -28 -14z"/>
<path d="M1894 6535 c-3 -8 -3 -18 0 -23 3 -6 -2 -16 -11 -23 -15 -13 -16 -11
-9 14 4 16 2 27 -4 27 -5 0 -12 -11 -16 -25 -3 -14 -11 -22 -18 -19 -6 3 -3
-1 7 -9 24 -20 26 -160 1 -150 -13 5 -14 17 -8 69 5 48 3 64 -6 64 -8 0 -11
-9 -8 -25 3 -15 1 -25 -6 -25 -7 0 -10 -15 -8 -41 3 -31 0 -44 -12 -51 -13 -7
-16 -27 -14 -116 0 -59 3 -92 5 -74 2 17 9 32 15 32 6 0 8 21 6 52 -2 35 1 53
10 55 8 3 12 -9 11 -44 -1 -74 -14 -138 -26 -136 -9 2 -9 -5 0 -30 l11 -32 7
33 c11 53 20 50 13 -4 -4 -27 -12 -52 -18 -56 -6 -4 -4 -9 7 -14 9 -3 17 -10
17 -16 0 -5 -4 -6 -10 -3 -5 3 -10 -1 -10 -9 0 -9 5 -16 10 -16 6 0 10 -9 10
-20 0 -11 5 -20 10 -20 6 0 10 22 10 49 0 49 1 50 33 53 l32 3 1 233 c0 128
-3 235 -8 238 -5 3 -6 21 -2 40 6 34 -11 50 -22 19z"/>
<path d="M2600 6539 c0 -11 47 -12 54 -1 3 5 -8 9 -24 10 -17 0 -30 -4 -30 -9z"/>
<path d="M4080 6534 c0 -9 55 -53 67 -54 6 0 15 6 19 13 4 6 3 9 -1 4 -5 -4
-18 1 -29 11 -22 19 -56 35 -56 26z"/>
<path d="M2560 6510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2413 6475 c1 -5 -4 -9 -10 -8 -9 2 -11 -7 -6 -30 7 -38 13 -48 13
-21 0 27 19 44 47 44 36 0 27 17 -11 22 -21 2 -34 0 -33 -7z"/>
<path d="M2500 6470 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M4204 6468 c-4 -6 -3 -8 4 -4 6 3 17 -1 25 -9 9 -8 20 -15 26 -15 6
0 11 -9 11 -20 0 -11 -7 -20 -16 -20 -9 0 -12 5 -8 12 4 7 3 8 -4 4 -7 -4 -27
4 -45 18 -19 15 -38 26 -43 26 -4 0 2 -7 14 -16 12 -8 22 -22 22 -30 0 -14 12
-18 39 -15 8 2 10 -2 5 -10 -5 -8 -3 -10 5 -5 7 5 18 1 25 -9 14 -19 47 -17
54 3 2 7 -6 12 -20 13 -17 1 -19 2 -5 6 10 3 25 -1 33 -8 12 -10 14 -8 11 7
-1 11 -6 19 -10 19 -5 0 -13 8 -18 17 -5 9 -23 19 -39 23 -17 4 -30 11 -30 16
0 13 -27 11 -36 -3z"/>
<path d="M2447 6443 c-4 -3 -7 -12 -7 -19 0 -9 6 -10 25 -1 14 7 25 15 25 20
0 9 -34 9 -43 0z"/>
<path d="M4350 6410 c0 -6 8 -10 18 -11 15 0 15 -1 -2 -8 -18 -7 -18 -8 -2
-14 10 -4 29 -7 42 -7 16 0 27 -8 33 -22 10 -28 -5 -37 -22 -14 -7 9 -30 19
-52 22 -38 5 -39 4 -21 -10 11 -9 26 -16 33 -16 7 0 13 -5 13 -12 0 -6 3 -8 7
-5 3 4 12 2 19 -4 13 -10 64 -2 64 11 0 14 -49 60 -65 60 -8 0 -15 4 -15 8 0
5 -11 14 -25 20 -15 7 -25 8 -25 2z"/>
<path d="M2320 6385 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M2362 6373 c-12 -21 -11 -48 2 -60 4 -4 6 -3 5 2 -2 6 3 21 10 34 9
15 10 26 3 33 -7 7 -14 4 -20 -9z"/>
<path d="M1785 6360 c-4 -12 -5 -24 -2 -27 3 -2 8 5 12 17 4 12 5 24 2 27 -3
2 -8 -5 -12 -17z"/>
<path d="M2251 6322 c-6 -2 -11 -22 -11 -44 0 -35 2 -40 20 -35 16 4 20 0 20
-19 0 -29 4 -30 30 -4 11 11 17 20 14 20 -3 0 2 7 10 16 20 19 21 38 2 31 -9
-4 -12 0 -9 13 4 14 -2 18 -30 22 -19 2 -40 2 -46 0z"/>
<path d="M4497 6323 c-11 -11 -8 -40 6 -51 20 -16 57 -24 57 -13 0 6 -9 11
-20 11 -21 0 -27 15 -10 25 5 3 7 12 3 20 -5 16 -25 20 -36 8z"/>
<path d="M4555 6290 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M4596 6265 c-3 -8 3 -19 12 -24 15 -9 15 -10 -2 -11 -15 0 -17 -3 -8
-12 8 -8 15 -8 27 2 13 10 15 9 15 -8 0 -20 19 -33 48 -31 13 0 11 3 -5 11
-23 11 -32 48 -13 48 6 0 10 -7 10 -15 0 -8 16 -18 37 -24 48 -14 54 -6 12 14
-19 8 -45 22 -59 30 -19 11 -26 11 -32 2 -5 -8 -8 -7 -8 4 0 22 -27 32 -34 14z"/>
<path d="M4720 6172 c0 -9 36 -35 99 -69 28 -16 31 -16 31 -3 0 5 -11 12 -25
16 -25 6 -35 24 -14 24 20 0 49 -21 49 -35 0 -8 5 -15 11 -15 5 0 7 -4 4 -10
-4 -6 7 -10 24 -10 17 0 31 4 31 9 0 10 -65 56 -110 77 -8 4 -18 12 -22 18 -5
8 -14 7 -28 -2 -17 -10 -25 -10 -36 -1 -8 7 -14 7 -14 1z m80 -22 c0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>
<path d="M2237 6153 c-9 -8 -9 -180 0 -345 l6 -128 29 0 30 0 -4 80 c-2 44 0
81 3 83 4 1 3 12 -2 25 -10 26 -29 21 -29 -9 0 -10 -4 -19 -10 -19 -5 0 -10
15 -10 34 0 19 -2 40 -3 48 -2 7 -2 10 0 6 8 -14 31 -9 37 8 8 22 8 199 -1
190 -4 -3 -6 -42 -4 -86 1 -50 -2 -80 -8 -80 -7 0 -8 21 -4 60 5 52 4 60 -11
60 -12 0 -16 9 -15 33 0 17 3 26 6 20 8 -20 23 -15 23 7 0 19 -20 27 -33 13z"/>
<path d="M4940 6061 c0 -5 6 -12 13 -15 6 -4 2 -4 -11 -2 -19 4 -21 2 -11 -10
6 -8 20 -14 30 -14 10 0 20 -4 23 -9 3 -4 18 -6 33 -4 l28 4 -25 -16 c-21 -14
-22 -16 -5 -12 21 4 49 -16 38 -27 -3 -3 7 -10 22 -16 15 -6 30 -9 32 -6 3 2
-5 7 -16 11 -12 3 -21 11 -21 17 0 5 7 8 15 4 31 -12 12 10 -37 42 -39 26 -52
31 -59 21 -6 -10 -11 -6 -18 14 -10 25 -31 38 -31 18z"/>
<path d="M5120 5940 c0 -5 6 -10 13 -11 6 0 3 -4 -8 -8 -19 -8 -19 -8 2 -19
17 -10 22 -9 26 7 2 10 -3 23 -14 30 -12 7 -19 7 -19 1z"/>
<path d="M5180 5881 c0 -9 24 -21 42 -21 6 0 6 6 -2 15 -14 16 -40 20 -40 6z"/>
<path d="M1875 5849 c-4 -17 -4 -33 -1 -36 3 -4 6 -1 6 5 0 7 9 12 19 12 16 0
20 -10 26 -64 4 -36 5 -68 2 -73 -3 -5 7 -7 24 -5 20 3 29 0 27 -8 -2 -7 3
-15 10 -17 6 -3 12 0 12 6 0 6 5 11 10 11 6 0 10 -26 9 -61 -1 -35 3 -65 10
-72 10 -9 11 -6 6 16 -5 17 -3 27 4 27 6 0 11 -7 11 -15 0 -8 7 -15 16 -15 12
0 14 5 8 18 -13 30 -17 102 -5 102 7 0 11 28 11 80 l0 80 -90 0 c-83 0 -90 1
-90 20 0 31 -18 24 -25 -11z"/>
<path d="M2321 5804 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5277 5813 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"/>
<path d="M1890 5776 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>
<path d="M5307 5771 c3 -11 12 -22 20 -25 11 -5 13 -2 9 10 -3 8 -6 20 -6 25
0 5 -6 9 -14 9 -9 0 -12 -7 -9 -19z"/>
<path d="M2330 5770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5370 5740 c0 -6 7 -13 15 -17 24 -9 27 1 5 15 -13 8 -20 8 -20 2z"/>
<path d="M2320 5696 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M2360 5698 c0 -9 7 -19 16 -22 24 -9 24 -4 3 18 -16 16 -19 17 -19 4z"/>
<path d="M5430 5673 c0 -5 9 -14 20 -21 11 -7 20 -8 20 -2 0 5 -9 14 -20 20
-11 6 -20 7 -20 3z"/>
<path d="M2401 5634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1992 5615 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5490 5620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2407 5593 c-4 -3 -7 -21 -7 -40 0 -24 5 -33 16 -33 14 0 14 3 4 15
-7 9 -10 19 -6 22 4 4 18 -6 32 -22 24 -29 25 -29 18 -5 -3 14 -12 30 -20 36
-8 6 -14 16 -14 23 0 12 -13 15 -23 4z"/>
<path d="M5550 5553 c0 -5 9 -14 20 -21 11 -7 20 -8 20 -2 0 5 -9 14 -20 20
-11 6 -20 7 -20 3z"/>
<path d="M2047 5526 c-3 -7 4 -18 14 -24 16 -10 19 -9 19 7 0 22 -26 36 -33
17z"/>
<path d="M2146 5505 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M5610 5512 c0 -7 16 -29 36 -50 19 -20 31 -28 25 -17 -18 33 -61 81
-61 67z"/>
<path d="M2096 5502 c-9 -15 12 -54 25 -47 12 8 11 18 -4 40 -10 12 -16 14
-21 7z"/>
<path d="M2480 5450 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2115 5430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2484 5418 c-3 -4 1 -8 10 -8 8 0 22 -3 32 -7 11 -4 15 -2 12 7 -5
15 -46 21 -54 8z"/>
<path d="M2140 5401 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M2485 5380 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M5730 5366 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M2180 5352 c0 -5 14 -21 30 -37 36 -34 40 -22 5 18 -25 28 -35 33
-35 19z"/>
<path d="M2225 5350 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2449 5350 c-1 -5 -2 -14 -3 -20 0 -5 -10 -16 -21 -24 -14 -11 -16
-15 -6 -16 8 0 29 16 47 35 31 35 31 35 8 35 -13 0 -25 -4 -25 -10z"/>
<path d="M2391 5318 c1 -24 3 -27 6 -10 2 12 11 22 19 22 8 0 14 5 14 10 0 6
-9 10 -20 10 -16 0 -20 -6 -19 -32z"/>
<path d="M5760 5328 c0 -9 7 -18 15 -22 8 -3 15 -11 15 -19 0 -7 14 -26 30
-42 17 -16 30 -24 30 -18 0 5 -9 18 -20 28 -11 10 -20 23 -20 30 0 6 -11 22
-25 35 -19 18 -25 20 -25 8z"/>
<path d="M2270 5259 c0 -24 20 -25 21 -1 0 14 3 13 16 -5 l15 -23 25 20 c14
11 30 17 37 13 7 -5 5 -9 -6 -11 -18 -3 -34 -32 -18 -32 6 0 24 13 40 29 l30
28 -80 1 c-72 0 -80 -2 -80 -19z"/>
<path d="M2310 5211 c0 -5 9 -11 21 -14 16 -4 18 -3 8 9 -13 16 -29 19 -29 5z"/>
<path d="M5861 5202 c-1 -11 47 -92 55 -92 7 0 -10 36 -26 55 -10 11 -20 27
-23 35 -3 10 -6 10 -6 2z"/>
<path d="M5933 5070 c6 -16 15 -30 19 -30 4 0 8 -9 8 -19 0 -11 5 -23 10 -26
17 -10 11 23 -10 65 -24 47 -43 54 -27 10z"/>
<path d="M5993 4951 c0 -17 8 -33 19 -40 14 -9 18 -8 18 3 0 8 -4 18 -10 21
-5 3 -10 15 -10 26 0 10 -4 19 -8 19 -5 0 -9 -13 -9 -29z"/>
<path d="M6021 4865 c0 -27 11 -49 27 -52 16 -4 15 24 -2 34 -7 4 -16 15 -19
23 -4 11 -5 9 -6 -5z"/>
<path d="M6056 4772 c-2 -4 -1 -14 4 -22 5 -8 6 -27 3 -44 -4 -21 -3 -27 5
-22 8 5 12 -2 11 -21 0 -25 -1 -26 -9 -8 -7 16 -9 17 -9 3 -1 -9 4 -20 9 -23
6 -3 10 -21 10 -40 0 -19 3 -36 8 -38 8 -6 6 79 -4 161 -6 50 -17 71 -28 54z"/>
<path d="M6079 4513 c-2 -24 -2 -25 -6 -5 -5 26 -23 20 -23 -9 0 -12 -4 -17
-11 -13 -7 4 -7 -1 1 -16 11 -21 12 -21 26 -3 14 17 15 16 9 -9 -3 -16 -1 -28
5 -28 5 0 10 24 10 55 0 56 -9 78 -11 28z"/>
<path d="M6015 4417 c-6 -24 -3 -34 19 -55 25 -26 26 -26 26 -5 0 12 6 23 13
26 6 2 9 8 5 12 -4 4 -14 2 -22 -4 -10 -8 -16 -9 -21 -1 -3 5 1 10 9 10 9 0
16 7 16 15 0 18 -20 20 -21 3 0 -7 -4 -4 -9 7 -7 18 -8 18 -15 -8z"/>
<path d="M3797 4413 c-7 -11 23 -83 34 -83 5 0 9 9 9 21 0 17 4 20 24 15 14
-3 27 -2 31 4 3 5 -6 12 -20 16 -13 3 -22 11 -19 16 4 6 -2 8 -15 3 -12 -4
-21 -2 -21 4 0 13 -17 15 -23 4z"/>
<path d="M3943 4354 c15 -8 25 -19 22 -24 -4 -6 3 -10 14 -10 18 0 21 -6 21
-39 0 -43 -11 -52 -56 -43 -26 5 -28 3 -16 -11 8 -9 11 -21 7 -27 -4 -6 7 -15
24 -21 17 -6 31 -16 31 -22 0 -6 3 -8 6 -4 3 3 24 -1 46 -9 23 -8 46 -14 52
-13 28 3 46 -2 43 -13 -1 -7 9 -13 23 -14 14 -1 36 -5 50 -9 21 -6 23 -5 13 6
-17 18 -16 29 2 29 8 0 15 -9 15 -20 0 -17 7 -20 40 -20 38 0 40 2 40 29 0 16
2 36 5 44 3 7 0 14 -7 15 -44 5 -78 16 -78 24 0 13 92 -2 111 -18 21 -18 40
-18 33 1 -3 8 -25 20 -48 26 -87 23 -142 43 -158 57 -14 12 -18 12 -18 2 0 -9
-5 -8 -19 4 -10 9 -31 18 -46 20 -15 2 -32 10 -37 20 -6 11 -16 14 -34 10 -17
-4 -24 -2 -24 8 0 16 -41 38 -68 38 -12 -1 -8 -6 11 -16z m137 -90 c0 -11 6
-13 19 -9 11 3 22 1 26 -5 3 -5 15 -10 26 -10 10 0 19 -6 19 -14 0 -26 -12
-46 -26 -46 -16 0 -65 29 -76 45 -5 6 -8 5 -8 -3 0 -7 -4 -10 -10 -7 -5 3 -10
15 -10 26 0 10 -4 19 -10 19 -5 0 -10 5 -10 10 0 6 14 10 30 10 21 0 30 -5 30
-16z m-90 -54 c8 -5 12 -12 8 -15 -10 -10 -49 5 -43 16 8 11 16 11 35 -1z
m298 -47 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m-80 -10
c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m-33 -23 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M3726 4334 c-14 -15 -26 -32 -26 -39 0 -18 46 -35 79 -29 16 3 39 1
52 -6 23 -11 23 -10 5 4 -17 14 -18 16 -3 16 26 0 20 33 -8 47 -14 6 -23 7
-19 2 3 -5 -2 -6 -10 -3 -9 3 -13 11 -9 18 3 6 3 8 -2 4 -4 -4 -14 -3 -20 2
-9 7 -20 2 -39 -16z m30 -29 c-4 -24 -13 -27 -19 -5 -3 13 0 20 8 20 7 0 12
-7 11 -15z"/>
<path d="M3866 4341 c-3 -5 10 -11 30 -13 34 -4 50 3 37 16 -10 9 -61 7 -67
-3z"/>
<path d="M5930 4275 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5950 4244 c-14 -7 -34 -11 -44 -8 -14 5 -27 -3 -47 -26 -16 -17 -32
-29 -38 -26 -5 3 -29 -3 -53 -13 -50 -23 -38 -28 14 -6 34 13 36 13 57 -11 18
-20 20 -28 10 -40 -10 -12 -9 -13 7 -7 10 3 23 12 27 20 4 7 13 13 20 13 20 0
78 66 83 94 5 29 0 30 -36 10z m4 -35 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15
3 5 10 7 15 3 5 -3 7 -10 3 -15z m-34 -17 c0 -15 -18 -29 -59 -45 -2 -1 -6 8
-8 21 -1 13 0 21 5 18 4 -2 18 2 32 10 32 17 30 17 30 -4z"/>
<path d="M3860 4230 c20 -13 40 -13 40 0 0 6 -12 10 -27 10 -22 0 -25 -2 -13
-10z"/>
<path d="M3897 4214 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
<path d="M4400 4126 c0 -53 -3 -65 -17 -69 -15 -3 -15 -5 -3 -9 65 -22 90 -28
104 -22 9 3 28 -2 42 -11 19 -12 29 -14 34 -6 4 8 43 11 121 9 110 -3 114 -4
117 -25 3 -21 24 -30 56 -24 5 1 6 -5 3 -14 -5 -12 3 -15 51 -14 55 2 56 2 17
9 l-40 7 37 2 c20 0 39 -3 42 -8 11 -17 101 -23 248 -16 81 4 148 11 148 16 0
5 -18 9 -40 9 -22 0 -40 5 -40 10 0 7 13 8 38 4 40 -8 44 -8 115 -6 27 1 46 6
42 11 -3 5 7 12 22 14 20 3 16 4 -14 6 -24 0 -43 6 -43 11 0 15 157 13 162 -2
3 -9 13 -8 39 2 19 8 37 14 42 12 4 -1 24 5 43 13 24 10 34 20 31 29 -4 11 3
16 26 18 26 2 33 8 35 30 l3 28 -63 -4 c-35 -2 -66 -4 -69 -5 -4 -1 -9 -11
-13 -24 -5 -22 -9 -23 -117 -24 -61 0 -114 -5 -118 -10 -11 -17 -158 -22 -396
-11 -183 7 -232 12 -242 24 -8 11 -21 13 -47 9 -22 -5 -36 -3 -36 3 0 13 -84
42 -122 42 -15 0 -28 -4 -28 -10 0 -5 -18 -10 -39 -10 -22 0 -42 -6 -45 -12
-4 -7 -4 -2 -2 11 3 13 1 20 -4 15 -4 -5 -10 -20 -12 -34 -2 -20 -9 -25 -33
-27 -16 -1 -22 1 -12 3 20 6 25 80 5 87 -7 2 1 3 17 1 32 -4 19 8 -22 20 -22
7 -23 5 -23 -58z m240 -6 c0 -5 6 -10 14 -10 7 0 19 -7 26 -15 8 -10 30 -15
61 -15 27 0 49 -4 49 -10 0 -6 -32 -10 -75 -10 -43 0 -75 -4 -75 -10 0 -5 -9
-10 -20 -10 -15 0 -20 7 -20 24 0 18 -7 26 -22 29 -19 4 -18 4 5 6 16 0 37 -4
49 -10 11 -6 23 -7 26 -3 4 4 2 9 -4 11 -6 2 -20 10 -30 18 -17 13 -18 14 -1
15 9 0 17 -4 17 -10z m1090 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7
10 15 10 8 0 15 -4 15 -10z m70 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-85 -44 c0 -11 -6 -21 -14 -24 -10 -3 -12 0
-7 13 4 11 1 22 -6 27 -9 6 -7 8 7 6 12 -2 20 -11 20 -22z m-1186 3 c46 -16
38 -29 -19 -27 -55 3 -55 3 -37 23 15 18 17 18 56 4z m566 -40 c7 -9 -3 -10
-39 -5 -27 3 -59 6 -72 6 -13 0 -24 5 -24 11 0 15 120 4 135 -12z m295 11 c12
-8 11 -10 -5 -9 -11 1 -31 5 -45 9 -24 8 -24 8 5 9 17 0 37 -4 45 -9z m-207
-7 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m205 -24 c27 -1
27 -1 -3 -9 -18 -5 -23 -8 -11 -9 11 0 17 -4 13 -7 -3 -3 -15 -3 -28 1 -17 6
-19 9 -8 17 10 7 0 8 -28 4 -25 -4 -43 -2 -43 3 0 12 74 19 78 9 2 -5 16 -9
30 -9z m-189 -5 c2 -2 -25 -4 -60 -4 -39 0 -59 4 -51 9 13 8 101 3 111 -5z
m-178 -5 c-14 -5 -52 -12 -85 -14 l-61 -5 45 14 c25 7 63 14 85 14 36 1 37 0
16 -9z m99 -19 c0 -5 -13 -10 -29 -10 -16 0 -41 -2 -57 -4 -16 -2 -21 -1 -11
1 9 2 17 9 17 14 0 5 18 9 40 9 22 0 40 -4 40 -10z"/>
<path d="M4868 4083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4258 4073 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4323 4063 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4666 3991 c5 -5 20 -12 34 -16 22 -7 23 -6 8 9 -9 9 -25 16 -34 16
-11 0 -14 -3 -8 -9z"/>
<path d="M4728 3993 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5568 3993 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5520 3969 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5403 3943 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4437 3359 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
